<template>
    <div class="c-client-settings-page">
      <div v-if="this.$xp.clientsettings.available.includes('fontsize')" class="client-setting">
        <h2 class="client-setting-heading">{{ $t('global.settingsFontsizeTitle') }}</h2>
        <div class="client-setting-description">
          {{ $t('global.settingsFontsizeDescr') }}
        </div>
        <div class="client-setting-options">
          <form>
            <div v-for="sizeinpercent in this.$xp.clientsettings.fontsizes" :key="sizeinpercent">
              <input v-bind:id="'setfontsize' + sizeinpercent" name="setfontsize" :value=sizeinpercent @change.prevent="changeFontSize(sizeinpercent)" :checked="(!nCurrentFontsize && sizeinpercent === 100) || sizeinpercent === nCurrentFontsize" type="radio"/> <label v-bind:for="'setfontsize' + sizeinpercent">{{ sizeinpercent }}%</label>
            </div>
          </form>
        </div>
      </div>
      <div v-if="this.$xp.clientsettings.available.includes('outlines')" class="client-setting">
        <h2 class="client-setting-heading">{{ $t('global.settingsOutlinesTitle') }}</h2>
        <div class="client-setting-description">
          {{ $t('global.settingsOutlinesDescr') }}
        </div>
        <div class="client-setting-options">
          <form>
            <div>
              <input id="setoutline-off" name="setfontsize" :value=0 @change.prevent="changeOutlines(0)" :checked="!bShowOutlines" type="radio"/> <label for="setoutline-off">{{ $t('global.off') }}</label>
            </div>
            <div>
              <input id="setoutline-on" name="setfontsize" :value=1 @change.prevent="changeOutlines(1)" :checked="bShowOutlines" type="radio"/> <label for="setoutline-on">{{ $t('global.on') }}</label>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'

export default {
  mixins: [pageMixin],
  data () {
    return {
      nCurrentFontsize: parseInt(this.$xp.clientsettings.getClientSetting('fontsize')),
      bShowOutlines: parseInt(this.$xp.clientsettings.getClientSetting('outlines'))
    }
  },
  methods: {
    changeFontSize (NewValue) {
      if (NewValue >= 100 && NewValue <= 200) {
        this.$xp.clientsettings.saveClientSetting('fontsize', NewValue)
      } else {
        this.$xp.clientsettings.removeClientSetting('fontsize')
      }
      this.$xp.clientsettings.applyClientSettings()
    },
    changeOutlines (NewValue) {
      if (NewValue) {
        this.$xp.clientsettings.saveClientSetting('outlines', 1)
      } else {
        this.$xp.clientsettings.removeClientSetting('outlines')
      }
      this.$xp.clientsettings.applyClientSettings()
    }
  }
}
</script>
<style>
  .client-setting {
    margin-bottom: 2em;
  }

  .client-setting-heading {
    margin: 0 0 3px 0;
  }
</style>
